html,
body {
  font-family: "Museo Sans", "Museo Cyrillic", "Helvetica", "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  margin-bottom: 150px
}

.content {
  flex: 1 0 auto;
  padding: 10px;
  height: 100vh;
}

.gutter {
  padding-left: 10px;
  padding-right: 10px;
}
